<template>
  <div class="center-container">
    <!-- 头 -->
    <div class="flex">
      <div class="img-head">
        <img
          :src="
            userInfo.gender === 2
              ? require('../../assets/images/user/famale.png')
              : require('../../assets/images/user/male.png')
          "
          alt=""
        />
      </div>
      <div class="flex fleX-direction align-center">
        <div class="name">{{ userInfo.realName }}</div>
        <div class="btn-yrz" v-if="userInfo.realNameCheck">
          <img src="../../assets/images/user/rz.png" /> 已认证
        </div>
        <div v-else class="btn-yrz">未认证</div>
      </div>
    </div>

    <div class="home-body">
      <div class="">
        <div class="rem24 lh17 mb7">待付总额</div>
        <div class="rem48 lh28">{{ allTotal }}</div>
      </div>
      <div>
        <div class="rem24 lh17 mb7">七日待付总额(元)</div>
        <div class="rem48 lh28 c-FE4D4DFF">{{ sevenTotal }}</div>
      </div>
    </div>

    <div class="home-mennu" @click="toUnsetlLoanList">
      <div class="flex align-center">
        <img src="../../assets/images/user/dhzd.png" class="icons" />待付账单
      </div>
      <van-icon name="arrow" class="mr16" />
    </div>

    <div class="home-mennu" @click="toRaypamentList">
      <div class="flex align-center">
        <img src="../../assets/images/user/jkjl.png" class="icons" />融资记录
      </div>
      <van-icon name="arrow" class="mr16" />
    </div>

    <div class="home-mennu" @click="setPage">
      <div class="flex align-center">
        <img src="../../assets/images/user/sz.png" class="icons" />账户设置
      </div>
      <van-icon name="arrow" class="mr16" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { getUid } from '@/utils/auth'
import { getUserInfo } from '@/apis/userCenter/index'
import { getRepaySumAmtInfoApi } from '@/apis/repayment/index'

Vue.use(Icon)
export default {
  data() {
    return {
      userInfo: {
        maskCardCode: '',
        realName: '',
        realNameCheck: false,
        gender: null,
      },
      allTotal: '0.00',
      sevenTotal: '0.00',
    }
  },
  created() {
    getUserInfo().then((res) => {
      let {
        data: { maskCardCode, realName, realNameCheck, gender },
      } = res || { data: {} }
      realName = realNameCheck ? realName : '未实名'
      this.userInfo = { maskCardCode, realName, realNameCheck, gender }
      this.getAllAmt()
    })
  },
  methods: {
    // 借款记录
    toRaypamentList() {
      this.$router.push({
        path: '/repaymentList',
        query: { type: 'Loan' },
      })
    },
    // 待还账单
    toUnsetlLoanList() {
      this.$router.push({
        path: '/repaymentList',
        query: { type: 'UnsetlLoan' },
      })
    },
    setPage() {
      this.$router.push('/userCenter')
    },
    async getAllAmt() {
      const userId = getUid()
      const {
        data: { instSumAmt: allTotal },
      } = (await getRepaySumAmtInfoApi({ userId, data: { getAllAmt: '1' } })) || {
        data: {},
      }
      this.allTotal = allTotal && Number(allTotal).toFixed(2)
      const {
        data: { instSumAmt: sevenTotal },
      } = (await getRepaySumAmtInfoApi({ userId, data: { getAllAmt: '2' } })) || { data: {} }
      this.sevenTotal = sevenTotal && Number(sevenTotal).toFixed(2)
    },
  },
}
</script>

<style lang="less" scoped>
.center-container {
  background-image: url('../../assets/images/user/blue-bgc.png');
  padding: 1.56rem /* 78/50 */ 0.3rem /* 15/50 */;
  background-size: 100% 3.78rem /* 189/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  .img-head {
    img {
      width: 1.02rem /* 51/50 */;
      height: 1.02rem /* 51/50 */;
      border-radius: 50%;
      margin-right: 0.32rem /* 16/50 */;
    }
    width: 1.02rem /* 51/50 */;
    height: 1.02rem /* 51/50 */;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0.32rem /* 16/50 */;
  }
  .btn-yrz {
    width: 1.18rem /* 59/50 */;
    height: 0.36rem /* 18/50 */;
    background-color: #def2ff;
    border-radius: 0.36rem /* 18/50 */;
    color: #4d7bfe;
    font-size: 0.2rem /* 10/50 */;
    line-height: 0.28rem /* 14/50 */;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.1rem /* 5/50 */;
    box-sizing: border-box;

    img {
      width: 0.18rem /* 9/50 */;
      height: 0.22rem /* 11/50 */;
    }
  }
  .name {
    height: 0.52rem;
    font-size: 0.38rem /* 19/50 */;
    line-height: 0.52rem /* 26/50 */;
    color: #fff;
  }

  .home-body {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0.08rem /* 4/50 */;
    padding: 0.48rem /* 24/50 */ 0.52rem /* 26/50 */;
    margin-top: 0.48rem; /* 24/50 */
  }
  .home-mennu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0.08rem /* 4/50 */;
    margin-top: 0.3rem /* 15/50 */;
  }
  .icons {
    width: 0.3rem /* 15/50 */;
    height: 0.34rem /* 17/50 */;
    margin: 0.38rem /* 19/50 */ 0.32rem /* 16/50 */;
  }
}
</style>
